/* @import url(./darktheme.css); */

.chat {
  display: flex;
  position: relative;
}

.chat__body {
  min-width: 300px;
  flex: 40%;
  border-right: 1px solid #dadada;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.chat__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: white;
}

.chat__header,
.chat__footer,
.chat__date-wrapper,
.chat__msg-group,
.chat__encryption-msg {
  z-index: 10;
}

.chat__header {
  /* Needed for the options btn to stay on top */
  z-index: 20;
}

/* Chat Header Component  */

.chat__avatar-wrapper {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.chat__contact-wrapper {
  flex: 1;
  cursor: pointer;
}

.chat__contact-name,
.chat__contact-desc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat__contact-name {
  color: #000000;
  font-size: 1.1rem;
  margin-bottom: 2px;
}

.chat__contact-desc {
  color: #00000099;
  font-size: 0.75rem;
}

.chat__actions {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.chat__action {
  margin-left: 25px;
  cursor: pointer;
}

.chat__action:not(.options-btn) {
  display: inline-block;
}

.chat__action-icon {
  color: rgb(145, 145, 145);
}

.chat__action-icon--search {
  width: 30px;
  height: 30px;
}

/* End Chat Header Component  */

/* Chat Content */

.chat__content {
  flex: 1;
  position: relative;
  background: #f5f5f5;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px 5% 2pc;
}

.noMessagePlaceholder {
  position: absolute;
  top: 66%;
  z-index: 99;
  width: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.noMessagePlaceholder .iconBox {
  text-align: center;
  margin-bottom: 90px;
}

.noMessagePlaceholder .iconBox p {
  margin-top: 22px;
  font-size: 20px;
  font-weight: bold;
}

.chat__date-wrapper {
  text-align: center;
  margin: 10px 0 14px;
  position: relative;
}

.chat__date {
  background: #e1f2fa;
  display: inline-block;
  color: #000000;
  font-size: 0.75rem;
  padding: 7px 10px;
  border-radius: 5px;
}

.chat__encryption-msg {
  background: #fdf4c5;
  color: #000000;
  font-size: 0.77rem;
  text-align: center;
  padding: 5px 10px;
  position: relative;
  margin-bottom: 8px;
  border-radius: 5px;
  line-height: 20px;
}

.chat__encryption-icon {
  color: #8c866c;
  margin-right: 5px;
  margin-bottom: -1px;
}

.chat__msg-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;
}

.chat__msg {
  padding: 6px 7px 8px 9px;
  margin-bottom: 12px;
  font-size: 0.85rem;
  color: #000000;
  width: fit-content;
  max-width: 95%;
  line-height: 20px;
  border-radius: 5px;
  position: relative;
  display: flex;
}

.chat__msg.chat__img-wrapper {
  padding: 4px;
  width: 95%;
}

.chat__msg--sent {
  display: inline-flex;
  padding: 17px 20px;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 25px;
  border: 2px solid #ebebeb;
  background: white;
  align-self: flex-end;
  color: black;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;

  /* background: #32afce;
  color: #fff; */
}

.chat__msg-copy-button {
  position: absolute;
  left: 20px;
  bottom: -32;
}

.chat__msg-copy-button img {
  width: 18px;
}

.parentCategory {
  font-size: 22px !important;
  font-weight: 500;
}

.chat__tooltip {
  position: absolute;
  left: 106.5%;
  top: 10px;
  background: #000;
  color: #fff;
  padding: 4px 10px;
  border-radius: 6px;
  line-height: 22px;
}

.header.chat-sidebar__header button {
  outline: none !important;
}

.chat__tooltip::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: -8px;
  border-top: 6px solid #000;
  border-right: 6px solid #000;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
}

.chat__msg--rxd {
  display: inline-flex;
  max-width: 100% !important;
  min-width: 100% !important;
  padding: 20px 30px;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 25px;
  border: 2px solid #ebebeb;
  background: linear-gradient(180deg, rgba(52, 176, 203, 0.10) 0%, rgba(87, 183, 175, 0.10) 62.5%, rgba(118, 143, 85, 0.10) 100%), #FFF;
}

.chat__msg--rxd a {
  color: #34b0cb !important;
}

/* .chat__msg-group>*:nth-child(1):not(.chat__msg--rxd)::before,
.chat__msg--rxd+.chat__msg--sent::before {
  right: -8px;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  border-top: 6px solid #32afce;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #32afce;
} */

.chat__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat__msg-filler {
  width: 65px;
  display: inline-block;
  height: 3px;
  background: transparent;
}

.chat__msg--sent>.chat__msg-footer>span {
  color: rgba(0, 0, 0, 0.45);
  margin-right: 6px;
}

.chat__msg-footer {
  /* position: absolute; */
  display: flex;
  align-items: center;
  right: 7px;
  bottom: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.7rem;
  font-weight: 500;
}

.chat__msg-status-icon {
  color: #b3b3b3;
  margin-left: 3px;
}

.chat__msg-status-icon--blue {
  color: #0da9e5;
}

.chat__img-wrapper .chat__msg-footer,
.chat__img-wrapper .chat__msg-options-icon,
.chat__img-wrapper .chat__msg-status-icon {
  color: white;
}

.chat__msg-options {
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 3px;
  pointer-events: none;
  transition: all 0.2s;
}

.chat__msg--rxd .chat__msg-options {
  background: #ccc;
}

.chat__msg--sent .chat__msg-options {
  background: #32afce;
}

.chat__img-wrapper .chat__msg-options {
  background: transparent;
}

.chat__msg:hover .chat__msg-options {
  opacity: 1;
  pointer-events: unset;
}

.chat__msg-options-icon {
  color: rgb(145, 145, 145);
  width: 20px;
  height: 20px;
}

/* End Chat Content */

.chat__footer {
  background: white;
  position: relative;
}

.chat__scroll-btn {
  position: absolute;
  right: 15px;
  bottom: 100px;
  width: 42px;
  height: 42px;
  z-index: -1;
  border-radius: 50%;
  color: rgb(145, 145, 145);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/* Emoji Tray */

.emojis__wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 0;
  min-height: 0;
  transition: all 0.4s ease;
  background: inherit;
}

.emojis__wrapper--active {
  height: 40vh;
  min-height: 350px;
  transition: all 0.4s ease;
}

.emojis__tabs {
  display: flex;
  align-items: center;
  height: 50px;
}

.emojis__tab {
  flex: 1;
  padding: 10px 5px 10px;
  text-align: center;
  position: relative;
}

.emojis__tab--active::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgb(0, 150, 136);
}

.emojis__tab-icon {
  color: rgba(0, 0, 0, 0.32);
}

.emojis__tab--active .emojis__tab-icon {
  color: rgba(0, 0, 0, 0.6);
}

.emojis__content {
  overflow-y: scroll;
  padding: 5px 20px;
  flex: 1;
}

.emojis__search {
  height: 40px;
  background: #e6e6e6;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  color: rgb(74, 74, 74);
  font-size: 0.9rem;
}

.emojis__search::placeholder {
  color: #989898;
}

.emojis__label {
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.85rem;
}

.emojis__grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.emojis__emoji {
  margin-right: 3px;
  margin-top: 3px;
  cursor: pointer;
}

/* End Emoji Tray */

/* Chat Footer Toolbar */

.chat__input-wrapper {
  padding: 10px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  width: 96%;
  margin: auto;
  margin-bottom: 26px;
}

.sendChatBtn {
  position: absolute;
  right: 12px;
  outline: none !important;
  box-shadow: none !important;
}

.sendChatBtn svg {
  height: 30px;
}

.filecogChatBtn {
  position: absolute;
  left: 20px;
  outline: none !important;
  box-shadow: none !important;
}

.filecogChatBtn svg {
  height: 30px;
}

.chat__input-icon {
  color: #919191;
  margin-left: 8px;
  margin-right: 8px;
  width: 28px;
  height: 28px;
  padding: 3px;
  border-radius: 50%;
}

.chat__input-icon--highlight {
  color: teal;
}

.chat__attach {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 50px;
}

.chat__attach-btn {
  transform: scale(0);
  opacity: 0;
  transition: all 0.5s ease;
}

.chat__attach-btn:nth-of-type(1) {
  transition-delay: 0.5s;
}

.chat__attach-btn:nth-of-type(2) {
  transition-delay: 0.4s;
}

.chat__attach-btn:nth-of-type(3) {
  transition-delay: 0.3s;
}

.chat__attach-btn:nth-of-type(4) {
  transition-delay: 0.2s;
}

.chat__attach-btn:nth-of-type(5) {
  transition-delay: 0.1s;
}

.chat__attach--active .chat__attach-btn {
  transform: scale(1);
  opacity: 1;
}

.chat__attach-btn {
  margin-bottom: 10px;
}

.chat__input-icon--pressed {
  background: rgba(0, 0, 0, 0.1);
}

.chat__input {
  background: white;
  color: rgb(74, 74, 74);
  padding: 20px 10px;
  border-radius: 22px;
  flex: 1;
  height: 100%;
  border: 1px solid #EBEBEB;
  ;
  min-height: 64px;
  max-height: 200px;
  resize: none;
}

.chat__input::placeholder {
  color: rgb(153, 153, 153);
  font-size: 0.9rem;
}

.chat__input::-webkit-scrollbar {
  display: none;
}

/* End Chat Footer Toolbar */

/* Chat Sidebar */

.chat-sidebar {
  width: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.1s ease;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-sidebar--active {
  flex: 30%;
  z-index: 999;
}

.dNone {
  display: none;
}

.chat-sidebar__header-icon {
  margin-right: 20px;
  color: rgb(145, 145, 145);
}

.chat-sidebar__heading {
  flex: 1;
  color: #000000;
  font-size: 1rem;
  margin-bottom: 2px;
}

.chat-sidebar__content {
  flex: 1;
}

.wrapper .question-container p {
  margin: 0;
}

.chat-sidebar__search-results {
  background: white;
  /* height: 90%; */
  padding: 1pc;
  color: #00000099;
  font-size: 0.85rem;
  overflow-y: scroll;
}

.promptsWrapper {
  margin-bottom: 20px;
}

.chat-sidebar__search-results .mainCategory {
  font-size: 20px;
  margin-bottom: 10px;
}

.question-content {
  font-size: 18px;
}

.profile {
  background: rgb(237, 237, 237);
  padding-bottom: 2pc;
}

.profile__section {
  background: white;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  padding: 10px 20px;
}

.profile__section--personal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
}

.profile__avatar-wrapper {
  width: 200px;
  width: 200px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__name {
  flex: 1;
  color: #000000;
  font-size: 1.2rem;
  align-self: flex-start;
}

.profile__heading-wrapper {
  margin-top: 5px;
  margin-bottom: 10px;
}

.profile__heading {
  color: rgb(0, 150, 136);
  font-size: 0.85rem;
  flex: 1;
}

.profile__heading-icon {
  color: rgb(145, 145, 145);
}

.profile__media-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile__media {
  width: 32%;
}

.profile__action,
.profile__about-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin-bottom: 5px;
  cursor: pointer;
}

.profile__action:not(:last-of-type),
.profile__about-item:not(:last-of-type),
.profile__group:not(:last-of-type) {
  border-bottom: 1px solid #ebebeb;
}

.profile__action-left {
  flex: 1;
}

.profile__action-text {
  display: block;
}

.profile__action-text--top,
.profile__about-item {
  font-weight: 500;
  margin-bottom: 5px;
}

.profile__action-text--bottom {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.45);
}

.profile__section--groups {
  padding-left: 0;
  padding-right: 0;
}

.profile__group,
.profile__group-heading {
  padding-left: 20px;
  padding-right: 20px;
}

.profile__group {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.profile__group:hover {
  background-color: #ebebeb;
}

.profile__group-content {
  flex: 1;
  overflow: hidden;
}

.profile__group-avatar-wrapper {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.profile__group-text {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile__group-text--top {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.profile__group-text--bottom {
  color: #00000099;
  font-size: 0.85rem;
  overflow: hidden;
}

.profile__section--danger {
  color: rgb(223, 51, 51);
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.profile__danger-icon {
  margin-right: 20px;
}

.profile__danger-text {
  flex: 1;
}

.prompt_box_sidebar {
  cursor: pointer;
  background-color: white;
  z-index: 999 !important;
  padding: 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.prompt_box_sidebar .prompt_text {
  color: #000;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pb-30px {
  padding-bottom: 30px;
}

.prompt_box {
  cursor: pointer;
  background-color: white;
  z-index: 999 !important;
  /* margin: 10px 10px; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
}

.prompt_text {
  display: flex;
  flex-direction: column;
}

.prompt_box:hover,
.prompt_box_sidebar:hover {
  background-color: rgba(236, 236, 241, 1) !important;
}

.prompt_icon {
  display: block;
}

/* .prompt_box:hover .prompt_icon,
.prompt_box_sidebar:hover .prompt_icon {
  display: block !important;
} */

/* .prompt_icon */

/* jumping dots for chat response loading */
.jumping-dots {
  padding: 10px;
  background: #dcd9d9;
  border-radius: 43px;
  display: block;
  width: fit-content;
}

.jumping-dots span {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  animation: jump 1s infinite;
  display: inline-block;
}

.jumping-dots .dot-1 {
  background-color: #9f9b9b;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  animation-delay: 200ms;
}

.jumping-dots .dot-2 {
  background-color: #9f9b9b;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  animation-delay: 400ms;
}

.jumping-dots .dot-3 {
  background-color: #9f9b9b;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  animation-delay: 600ms;
}

@keyframes jump {
  0% {
    bottom: 0px;
  }

  20% {
    bottom: 5px;
  }

  40% {
    bottom: 0px;
  }
}

/* accordions setup */
.explorePromts {
  width: fit-content;
  height: fit-content;
  padding: 6px 16px;
  border-radius: 20px;
  background: #000;
  color: #fff;
}

.explorePromts span {
  margin-left: 5px;
  vertical-align: middle;
}

/* main category wrapper */
.catWrapper {
  border: 2px solid #E5E5E5;
  border-radius: 5px;
  padding: 10px;
  overflow: hidden;
  margin-bottom: 16px;
}

.catWrapper .question-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
  text-align: left;
  color: #000;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.activeWrapper {
  background: var(--product-background, #F2F8F9);
}

.catWrapper .question-container p {
  margin: 0;
}

/* end main category wrapper */

.wrapper {
  padding-bottom: 8px;
  overflow: hidden;
}

.wrapper .question-container {
  width: 100%;
  text-align: left;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none !important;
}

.question-container.active:focus {
  outline: none;
}

.question-container.active {
  color: #0da9e5;
  outline: none !important;
  border: none !important;
}

.subCatAnswer {
  margin-left: 20px;
}

.subCatAnswer .answer-content {
  padding-top: 16px;
}

.wrapper .arrow {
  font-size: 2rem;
  transition: .2s ease-in-out;
}

.arrow.active {
  rotate: 180deg;
  color: #1db954;
}

.wrapper .answer-container {
  transition: height .2s ease-in-out;
}

.promptsTitle {
  border: 1px solid;
  padding: 0px 10px 8px 14px;
  border-radius: 8px;
  cursor: pointer;
}

.wrapper .answer-content,
.wrapper .answer-content span {
  padding: 1rem 0;
  font-size: 20px;
  font-style: italic;
}

.iconWrapper {
  position: absolute;
  bottom: -32px;
  right: 0px;
}

/* End  Chat Sidebar */

@media screen and (min-width: 1301px) {
  .chat__msg {
    max-width: 65%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .chat__msg {
    max-width: 75%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .chat__msg {
    max-width: 85%;
  }
}

@media screen and (max-width: 1000px) {
  .chat-sidebar {
    transition: transform 0.1s ease;
    transform: translateX(120vw);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .chat-sidebar--active {
    transform: translateX(0);
    transition: transform 0.1s ease;
  }
}

@media screen and (min-width: 750px) {
  .chat__msg.chat__img-wrapper {
    width: 40%;
    min-width: 300px;
    max-width: 400px;
  }
}

.modal-zz {
  display: flex;
  /* Center the modal */
  align-items: center;
  /* Center the modal */
  justify-content: center;
  /* Center the modal */
  position: fixed;
  /* Stay in place */
  z-index: +99999999999999999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content-zz {
  background-color: #fefefe;
  margin: auto;
  /* Centered */
  padding: 32px 6px;
  border: 1px solid #888;
  width: 38%;
  /* Could be more or less, depending on screen size */
  border-radius: 10px;
  position: relative;
  overflow: auto;
  border: 1px solid black;
  border-radius: 19px;

}

/* The Close Button */
.close-zz {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

}

.close-zz:hover,
.close-zz:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.loader-zzzz {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: -10px;
  position: absolute;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  width: 100px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.tooltip-text::before {
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background-color: #192733;
  padding: 5px;
  z-index: 1;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#copytext {
  top: -50px;
  left: -150%;
}

#copytext::before {
  top: 80%;
  left: 45%;
}

#braintext {
  top: -50px;
  left: -30%;
}

#braintext::before {
  top: 80%;
  left: 45%;
}

#uploadtext {
  top: -50px;
  left: -250%;
}

#uploadtext::before {
  top: 80%;
  left: 45%;
}

/* Ensure the parent container has a height of 100% */
.maindiv {

  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  padding: 40px 20px;
  border: none !important;
  overflow: scroll;
  box-sizing: border-box;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 20px 20px; */
  border: 1px dashed !important;
  overflow: scroll;
  height: 35vh;
  box-sizing: border-box;
}

.success-message {
  color: green;
}

.text-center {
  text-align: center;
}

.uploadbutton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 10px;
}

.file-list {
  margin-top: 2px;

}

.file-item {
  margin-bottom: 10px;
  text-align: left;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 5px;
  line-height: 0.9;
  position: relative;
}

/* Fallback for other browsers that don't support pseudo-elements */
.progress-z {
  width: 100%;
  border-radius: 10px;
}



.progress-z::-webkit-progress-value {
  background: rgba(117, 117, 117, 1);
}

.progress-z::-webkit-progress-bar {
  background: rgba(229, 229, 229, 1);
}

.uploadbutton {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: black;
  color: white;
}

.text-center {
  text-align: center;
}

.ellipsetext {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-bottom: 0px;
}

.crossicon-z {
  position: absolute;
  top: 5px;
  right: 10px;
}

.deleterow {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 0px;
}

.cancelbutton {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: white;
  color: black;
  border: 1px solid black;
  margin: 0px 10px;
}

.text-gray {
  color: #999999;
}

.lastpromptstyling {
  position: "fixed";
  top: 100;
  left: 100;
  z-index: +999999999999999999;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 10px;
}

.chat__msg .chat__msg--rxd>.markDownSetting {
  width: 100%;
}

.markDownSetting {
  width: 100%;
}

.markDownSetting ul,
.markDownSetting ol {
  margin-left: 20px;
}

.markDownSetting ul {
  line-height: 16px;
  list-style: disc;
  margin-left: 20px;
}

.markDownSetting ol {
  margin-left: 20px;
}

.markDownSetting table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.markDownSetting table thead tr {
  background-color: #0D0D0D;
  color: #ffffff;
  text-align: left;
}

.markDownSetting table th,
.markDownSetting table td {
  padding: 12px 15px;
}

.markDownSetting table tbody tr {
  border-bottom: 1px solid #c2c2c2;
}

.markDownSetting table tbody tr:nth-of-type(even) {
  background-color: #fbfbfb;
}

.markDownSetting table tbody tr:last-of-type {
  border-bottom: 2px solid #0D0D0D;
}

.markDownSetting table tbody tr.active-row {
  font-weight: bold;
  color: #0D0D0D;
}

.markDownSetting h1{
  font-size: 24px !important;
}
.markDownSetting h2{
  font-size: 22px !important;
}
.markDownSetting h3{
  font-size: 20px !important;
}
.markDownSetting h4{
  font-size: 18px !important;
}
.markDownSetting h5{
  font-size: 18px !important;
}
.markDownSetting h6{
  font-size: 18px !important;
}
.promptonchat{
  text-decoration: underline;
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
  position: fixed;
  background-color: white;
  border-radius: 5px;
  bottom: 111px;
}
.topsettingonresponse{
  position: absolute;
  bottom: -60px;
}
.topsettingonresponse button{
  outline: none !important;
}
.hovercolor{
  color: rgb(137, 128, 128);
}
.hovercolor:hover{
  color: black;
  cursor: pointer;
}
.position-absolute{
  position: absolute;
}
.position-relative{
  position: relative;
}
pre {
  white-space: pre-wrap; /* Wraps the content within the pre tag */
  word-wrap: break-word; /* Breaks long words if necessary */
  overflow-x: auto; /* Adds horizontal scroll if the content is too wide */
  max-width: 100%; /* Prevents it from overflowing the parent container */
  background-color: #f5f5f5; /* Light background for better readability */
  padding: 10px; /* Adds some padding */
  border-radius: 4px; /* Slightly rounded corners */
}
