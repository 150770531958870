.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.modal-content {
    max-height: 100vh;
    overflow-x:hidden;
    overflow-y: auto;
  }
  
  .scrollable-modal-content {
    max-height: 100vh;
    overflow-x:hidden;
    overflow-y: auto;
    padding: 30px;
  }

.modal-content {
    width: 600px;
    flex-shrink: 0;
    background-color: white;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    z-index: 1001;
    border-radius: 15px;
    border: 2px solid var(--black, #0D0D0D);
    background: #FFF;
}

.close-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal-overlay {
    animation: modalFadeIn 0.5s;
}

.modalText {
    text-align: left;
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 20px;
}

.modalDesc {
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: left;
    margin-bottom: 20px;
    line-height: 23px;
}

.formGroup {
    text-align: left;
}

.formGroup label {
    color: #000;
    font-family: 'Raleway', sans-serif;
    text-align: left;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
    border-radius: 15px;
    border: 1px solid var(--secondary-tint-1, #92A5AF);
    padding: 10px 20px;
    margin: 5px 0;
    outline: none;
}

.modalBtnGroup {
    text-align: right;
}

.okBtn {
    border-radius: 50px;
    border: 2px solid var(--black, #0D0D0D);
    background: var(--Web-support, #A9BB77);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    color: var(--white, #FCFCFC);
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 20px 20px auto auto;
    color: var(--white, #ffffff);
    padding: 11px 25px;
}

.disagreeBtn {
    border-radius: 50px;
    border: 2px solid var(--black, #0D0D0D);
    background: var(--black, #0D0D0D);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    color: var(--white, #FCFCFC);
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline;
    padding: 11px 25px;
    cursor: pointer;
}

.radio-group {
    display: flex;
}

.radio-button {
    display: flex;
    align-items: center;
}

.radio-button.left>label {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.radio-button.left {
    border-right: none;
}

.radio-button.right>label {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.radio-button.right {
    border-left: none;
}

.radio-input {
    display: none;
}

.radio-label {
    cursor: pointer;
    padding: 17px 32px;
    border: 1px solid #ccc;
    border-radius: 15px;
    border: 1px solid var(--secondary-tint-1, #92A5AF);
    background: var(--product-background, #E5E5E5);
}

.radio-input:checked+.radio-label {
    color: var(--black, #0D0D0D);
    font-family: 'Raleway', sans-serif;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    background-color: #ffffff;
    border-color: var(--black, #0D0D0D);
}
.roundofaddprompt{
    background-color: rgba(242, 248, 249, 1);
    color: black;
    border: 1px solid rgba(229, 229, 229, 1);
 
    padding: 10px 20px ;
    border-radius: 20px;
}
.z-w-60{
    width: 60%   ;
}
.z-w-100{
    width: 100%   ;
}
.input_od_addPrompt{
    border-radius: 40px !important;
    padding: 15px 20px !important;
    
}