/* App.css */
.chat-dropdown {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chat-dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 300px;
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 1;
  margin-top: 5px;
}

.btn-chat {
  background-color: #32afce;
  color: #fff;
  border-radius: 5px;
}

.btn-pencil,
.btn-newChat,
.btn-explore {
  border-radius: 5px;
  border: 2px solid var(--black, #0D0D0D);
  background: var(--black, #fff);
  color: #0D0D0D;
  margin-right: 10px;
  box-shadow: none !important;
  outline: none !important;
}

.btn-explore svg {
  margin-right: 8px;
  vertical-align: baseline;
}

.btn-newChat:hover,
.btn-explore:hover {
  background: var(--black, #0D0D0D);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
}
.btn-explore svg circle {
  fill: #0D0D0D !important;
}
.btn-explore:hover svg circle {
  fill: #fff !important;
}
.btn-explore:focus svg circle ,
.activeBtn svg circle {
  fill: #fff !important;
}

input {
  width: 100%;
  margin: 5px 0;
  padding: 8px;
}

button:disabled {
  cursor: not-allowed;
}

.activeBtn {
  background: var(--black, #0D0D0D);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  color: #fff !important;
  fill: #fff !important;
}
